export default [
  {
    title: 'Panel',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Siparişler',
    icon: 'ShoppingCartIcon',
    resource: 'm_order',
    children: [
      {
        title: 'Sipariş Listesi',
        route: 'order-list',
      },
    ],
  },
  {
    title: 'Sanalpos Ödemeleri',
    icon: 'CreditCardIcon',
    resource: 'm_payment',
    children: [
      {
        title: 'Ödeme Listesi',
        route: 'payment-list',
      },
    ],
  },
  {
    title: 'Kullanıcı',
    icon: 'UserIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'user-list',
      },
    ],
  },
  {
    title: 'Müşteri',
    icon: 'UsersIcon',
    resource: 'm_customer',
    children: [
      {
        title: 'Firmalar',
        route: 'customers-list',
        resource: 'm_customer',
        action: 'read',

      },
      {
        title: 'Fiyat Grupları',
        route: 'price-groups-list',
        resource: 'm_customer',
        action: 'read',

      },
    ],
  },
  {
    title: 'Ürün',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ürünler',
        route: 'products-list',
        resource: 'm_product',
        action: 'read',
      },
      {
        title: 'Markalar',
        route: 'brands-list',
        resource: 'm_product',
        action: 'read',
      },
      {
        title: 'Kategoriler',
        route: 'categories-list',
        resource: 'm_product',
        action: 'read',
      },
    ],
  },
  {
    title: 'Bildirim Gönder',
    icon: 'BellIcon',
    resource: 'm_notification',
    route: 'notifications-add',
  },

  {
    title: 'Ayarlar & Tanımlar',
    icon: 'SettingsIcon',
    resource: 'm_config',
    children: [

      {
        title: 'Ödeme Ayarları',
        route: 'settings-payment',
      },
      {
        title: 'İşlem Mail Listeleri',
        route: 'email-categories',
        resource: 'm_email_send',
      },
    ],
  },
  {
    title: 'Mağaza',
    icon: 'ShoppingBagIcon',
    resource: 'm_config',
    children: [
      {
        title: 'Menü Ayarları',
        route: 'shop-menu',
      },
      {
        title: 'Sayfa Düzenleyici',
        route: 'page-builder',
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    resource: 'm_report',
    children: [
      {
        title: 'Sipariş Raporu',
        route: 'reports-order-list',
      }, /*
      {
        title: 'Arama Raporu',
        route: 'reports-search',
      },
      {
        title: 'Kullanıcı Giriş Raporu',
        route: 'login-reports',
      },
      */
    ],
  },
]
